import metadata from './block.json';

// Global import
const { registerCheckoutFilters } = window.wc.blocksCheckout;


const updateCartItem = ( defaultValue, extensions, args ) => {

    const cart_item_key = args?.cartItem?.key;

    if ( cart_item_key ) {
        
        // Set product image.
    	const cartitem_giftcard_image = args?.cart?.extensions?.wt_gc_blocks?.cartitem_giftcard_image;

        if ( cartitem_giftcard_image && cartitem_giftcard_image[cart_item_key] ) {
            if ( args.cartItem.images.length ) {
                args.cartItem.images[0].src = cartitem_giftcard_image[cart_item_key];
                args.cartItem.images[0].thumbnail = cartitem_giftcard_image[cart_item_key];
            } else {
                args.cartItem.images = [ {
                    'id': 0, 
                    'src': cartitem_giftcard_image[cart_item_key], 
                    'thumbnail': cartitem_giftcard_image[cart_item_key],
                    'srcset': '',
                    'sizes': '',
                    'name': '',
                    'alt': '',
                }];
            }
        }

        // Set product title, permalink, catalog_visibility, description.
        const cartitem_giftproduct_title = args?.cart?.extensions?.wt_gc_blocks?.cartitem_giftproduct_title;
        const cartitem_giftproduct_link = args?.cart?.extensions?.wt_gc_blocks?.cartitem_giftproduct_link;
        
        if ( cartitem_giftproduct_link && cartitem_giftproduct_title && cartitem_giftproduct_link[ cart_item_key ] && cartitem_giftproduct_title[ cart_item_key ] ) {
            args.cartItem.description = '';
            args.cartItem.catalog_visibility = 'visible';
            args.cartItem.permalink = cartitem_giftproduct_link[ cart_item_key ];
            defaultValue = cartitem_giftproduct_title[ cart_item_key ];
        }
    }

	return defaultValue;
}

registerCheckoutFilters( 'wt-gc-blocks-update-cart-item', {
    itemName: updateCartItem,
} );